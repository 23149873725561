@import 'src/packages/assets/styles/typography';

:root[data-theme='light'] {
  --tab-border: rgba(197, 208, 229, 1);
  --tab-text: rgba(40, 43, 49, 0.8);
  --tab-active: rgba(210, 219, 236, 1);
  --tab-text-active: rgba(40, 43, 49, 0.8);
}

:root[data-theme='dark'] {
  --tab-border: rgba(255, 255, 255, 0.1);
  --tab-text: var(--contrast-transparent);
  --tab-active: var(--brand-1);
  --tab-text-active: var(--white-color);
}

.tooltipOverlay {
  padding: 0;
  overflow: hidden;
}

.wrapper {
  position: relative;

  &Alarm {
    background-color: rgba(235, 87, 87, 0.2);
  }

  &:not(&Focused):hover {
    .tab {
      &:not(.tabDisabled) > span {
        max-width: calc(100% - 8px);
      }

      &:not(.tabDisabled).tabDragging > span {
        max-width: 100%;
      }

      &:not(.tabDisabled):not(.tabActive):not(.tabDragging) {
        background: var(--hover);
      }
    }

    .closeButton {
      opacity: 1;
      color: var(--tab-text-active);
    }
  }

  &Dragging {
    z-index: 999;
  }
}

.tab {
  @extend .fontBase;

  min-width: 70px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 1px 8px;
  border-radius: 4px;
  background: transparent;
  border: 1px solid var(--tab-border);
  color: var(--tab-text);
  font-size: 13px;
  font-weight: 500;
  user-select: none;
  outline: none;
  overflow: hidden;
  cursor: pointer;

  > span {
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &:focus,
    &:active {
      color: inherit;
    }
  }

  &Dragging {
    &:not(.tabActive):not(.tabDisabled) {
      background-color: var(--workspace-header-bg);
    }

    span {
      text-overflow: initial;
    }
  }

  &Active {
    background: var(--tab-active);
    color: var(--tab-text-active);

    &:hover {
      color: var(--tab-text-active);
    }
  }

  &Disabled {
    border: 1px solid transparent;
    background: #0707134d;
    color: #ffffff33;
    cursor: not-allowed;

    &:hover {
      cursor: not-allowed;
    }
  }

  &:not(.tabActive) {
    .input {
      color: #ffffff80;
    }
  }
}

.tabName {
  white-space: nowrap;
}

.closeButton {
  position: absolute;
  opacity: 0;
  top: 1px;
  right: 0px;
  display: grid;
  place-items: center;
  background: none;
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
}

.closeIcon {
  width: 20px;
  height: 20px;
}

.input {
  @extend .fontBase;

  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
  font-weight: 500;

  &:focus,
  &:active {
    outline: none;
  }
}

.hiddenSpan {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 8px;
  opacity: 0;
  padding-left: 4px;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 500;
}

.alarmIcon {
  color: rgba(255, 101, 101, 1);
}
