:root[data-theme='light'] {
  --workspace-overlay-backgroundColor: rgba(0, 0, 0, 0.1);
  --workspace-header-bg: rgba(245, 248, 251, 1);
  --workspace-header-border-btm: rgba(223, 230, 242, 1);
}

:root[data-theme='dark'] {
  --workspace-overlay-backgroundColor: rgba(0, 0, 0, 0.3);
  --workspace-header-bg: var(--primary-2);
  --workspace-header-border-btm: var(--primary-3);
}

.wrapper {
  height: 100%;
  width: 100%;
  flex-grow: 1;

  &FullscreenMode {
    overflow-y: hidden;
  }
}

.header {
  display: grid;
  align-items: center;
  height: 34px;
  padding: 0 8px;
  grid-template-columns: auto 1fr;
  position: fixed;
  max-width: 100vw;
  width: 100%;
  z-index: 999;
  border-bottom: 2px solid var(--workspace-header-border-btm);
  background: var(--workspace-header-bg);
}

.loader {
  display: grid;
  place-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: var(--workspace-overlay-backgroundColor);
}
