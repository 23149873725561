:root[data-theme='light'] {
  --tabs-gradient: rgba(245, 248, 251, 1);
  --tabs-arrow: rgba(40, 43, 49, 0.8);
  --tabs-arrow-border: rgba(223, 230, 242, 1);
}

:root[data-theme='dark'] {
  --tabs-gradient: var(--primary-2);
  --tabs-arrow: var(--white-color);
  --tabs-arrow-border: rgba(255, 255, 255, 0.1);
}

.tabs {
  display: flex;
  column-gap: 2px;
  overflow: hidden;
  position: relative;

}

.carousel {
  display: flex;
  column-gap: 2px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.arrow {
  position: absolute;
  display: grid;
  place-items: center;
  width: 30px;
  font-size: 20px;
  height: 100%;
  white-space: nowrap;
  background: var(--tabs-gradient);
  border: none;
  cursor: pointer;
  color: var(--tabs-arrow);

  &::before {
    content: '';
    position: absolute;
    display: flex;
    width: 18px;
    height: 100%;
  }

  &Left {
    border-right: 1px solid var(--tabs-arrow-border);
    top: 0;
    left: 0;
    &::before {
      background: linear-gradient(90deg, var(--tabs-gradient) 40%, transparent 100%);
      left: calc(100% + 1px);
    }
  }

  &Right {
    border-left: 1px solid var(--tabs-arrow-border);
    top: 0;
    right: 0;

    &::before {
      right: calc(100% + 1px);
      background: linear-gradient(270deg, var(--tabs-gradient) 40%, transparent 100%);
    }
  }
}
