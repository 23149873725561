@use 'src/packages/assets/styles/typography';

:root[data-theme='light'] {
  --widget-and-group-text: rgba(40, 43, 49, 0.6);
  --widget-icon-bg: #d2dbec;
  --widget-graph-icon-bg: #eff3f9;
}

:root[data-theme='dark'] {
  --widget-and-group-text: var(--secondary-1);
  --widget-icon-bg: #1a202c;
  --widget-graph-icon-bg: #28313f;
}

.wrapper {
  max-width: 228px;
  cursor: pointer;
  margin-right: 16px;
  border-radius: 8px;
  padding: 4px;

  transition: 0.1s;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background-color: rgba(#657997, 0.15);
  }
}

.name {
  @extend .body-m-bold;

  margin-top: 16px;
  margin-bottom: 4px;
  color: var(--text-1);
}

.singleIcon {
  width: 84px;
}

.description {
  @extend .fontBase;

  font-size: 13px;
  color: var(--widget-and-group-text);
}

.iconsWrapper {
  width: 84px;
  height: 48px;
  border-radius: 8px;
  padding: 4px;
  display: flex;
  gap: 4px;
  background-color: var(--widget-icon-bg);
}

.iconsStubOuter {
  width: 84px;
  height: 48px;
  padding: 8px;
  border-radius: 8px;
  background-color: var(--primary-2);
}

.iconsStubInner {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: var(--primary-1);
}

.iconsColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 4px;
}

.graphIconWrapper {
  width: 54px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--widget-graph-icon-bg);
}
