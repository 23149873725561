@use 'src/packages/assets/styles/typography';

:root[data-theme='light'] {
  --well-list-icons: rgba(120, 140, 185, 1);
}

:root[data-theme='dark'] {
  --well-list-icons: rgb(148, 152, 158);
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 8px 4px 8px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .search {
    margin: 8px 0;
    padding: 3px 8px;
    border: none;
    border-radius: 4px;
    box-shadow: none;

    &Input {
      @extend .body-s;
      color: var(--text-2);

      &::placeholder {
        @extend .fontBase;
      }
    }
  }
}

.icon {
  width: 16px;
  height: 16px;
  stroke: var(--contrast);
  transition: var(--transition);
}

.searchIcon {
  width: 16px;
  height: 16px;
  color: var(--text-1);
}

.switchContainer {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);

  :global {
    --go-radio-button-group-flat-inactive-text: var(--contrast);

    .go-radio-button-group__item-label:nth-child(n) {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }
  }
}

.groupTypeContainer {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);

  :global {
    --go-radio-button-group-flat-inactive-text: var(--well-list-icons);

    .go-radio-button-group__item-label:nth-child(n) {
      border-bottom-width: 0;
    }
  }

  .groupTypeButton {
    height: 28px;
  }
}

.groupTypeIcon {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.list {
  max-height: 100%;
  align-items: center;

  .listItem {
    border-bottom: 0;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--primary-1);
    border-radius: 4px;
    padding: 2px 8px;
    cursor: pointer;

    &:last-child {
      margin: 0;
    }

    &Active {
      background: var(--brand-3);
    }
  }
}

.searchEraser {
  padding: 0;
  width: 12px;
  height: 12px;
  background: none;
}

.icon {
  width: 16px;
  height: 16px;
  stroke: var(--contrast);
  transition: var(--transition);
  color: var(--well-list-icons);
}
.activeIcon {
  color: var(--brand-4);
}

.iconButton {
  background: transparent;
  height: 16px;
  width: 16px;
  padding: 0;
}

.titleWrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.drillingIcon {
  color: var(--well-list-icons);
  margin-right: 8px;
  min-width: 16px;
  font-size: 16px;
  width: 16px;
  height: 16px;
}

.title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.buttons {
  display: flex;
}

.addButton {
  background: transparent;
  height: 24px;
  width: 24px;
  padding: 0;
}

.addButtonIcon {
  width: 16px;
  min-width: 16px;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  z-index: 999;
  background-color: var(--workspace-overlay-backgroundColor);
}
