@use 'src/packages/assets/styles/typography';

:root[data-theme='light'] {
  --widget-header-title: rgba(40, 43, 49, 0.6);
}

:root[data-theme='dark'] {
  --widget-header-title: var(--contrast-transparent);
}

.header {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr;
  gap: 0;
  padding: 4px 8px;
}

.progressText{
  @extend .dash-body-regular;

  color: var(--brand-4);
}

.controlsButton {
  background-color: transparent;
  width: 16px;
  padding: 0;
  height: 16px;

  .controlsButtonIcon {
    color: var(--widget-header-icons);
    width: 16px;
    height: 16px;
    min-width: 16px;
  }
}

.controls {
  display: flex;
  justify-content: end;
  align-items: center;
  column-gap: 10px;
}

.tooltipButtons {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.tooltipButton {
  width: 100%;
  justify-content: flex-start;
  text-align: left;
}

.widgetSection {
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sectionName {
  @extend .dash-body-bold;

  color: var(--go-text);
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.widgetTitle {
  display: flex;
  justify-content: start;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 140px;
}

.widgetName {
  font-size: 13px;
  font-family: 'Inter';
  color: var(--widget-header-title);
  padding-right: 30px;

  min-width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.draggableHandle {
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;

  &Draggable {
    cursor: move;
  }
}

.tooltipCircle {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.groupButton {
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  position: relative;
  cursor: pointer;
}

.groupButtonCircle {
  border-radius: 50%;
  width: 9px;
  height: 9px;
}

.progressBarContainer {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary-3);
  transition: height 0.5s ease-in-out;
  &__hidden {
    height: 0;
    visibility: hidden;
  }

  .progressBar {
    width: 0%;
    height: 2px;
    background-color: var(--brand-4);
    transition: width 0.5s ease;
  }
}
