@use 'src/packages/assets/styles/templates';

:root[data-theme='light'] {
  --sidebar-wrapper: white;
}

:root[data-theme='dark'] {
  --sidebar-wrapper: var(--primary-1);
}

$wrapperShadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 12px 27px 0px rgba(0, 0, 0, 0.19),
  0px 50px 50px 0px rgba(0, 0, 0, 0.17), 0px 112px 67px 0px rgba(0, 0, 0, 0.1), 0px 200px 80px 0px rgba(0, 0, 0, 0.03),
  0px 312px 87px 0px rgba(0, 0, 0, 0);

.wrapper {
  width: 280px;
  min-height: 100%;
  background-color: var(--sidebar-wrapper);
  box-shadow: $wrapperShadow;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: var(--transition);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;

  &__closed {
    width: 0;
    opacity: 0;

    @media (min-resolution: 101dpi) and (max-width: 1679px) {
      width: 0;
      opacity: 0;
    }
  }
}

.block {
  width: 280px;
}

.closeButton {
  @extend %close-cross;

  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 36px;
  right: 24px;
  z-index: 10;
  transition: opacity 0.2s, visibility 0.2s;

  &__opened {
    visibility: visible;
    opacity: 1;
  }

  &::before,
  &::after {
    height: 17px;
  }
}

.openButton {
  z-index: 999;
  position: fixed;
  bottom: 24px;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  font-size: 32px;
  left: 12px;
  border: none;
  color: var(--text-1);
  background: var(--main-wrapper-layout-bg);
  border-radius: 12px;
  cursor: pointer;
  transition: opacity 0.2s, visibility 0.2s;

  &__active {
    visibility: hidden;
    opacity: 0;
  }
}
