:root[data-theme='light'] {
  --collapse-text: rgba(40, 43, 49, 1);
  --collapse-arrow: rgba(120, 140, 185, 1);
  --collapse-item-title: rgba(40, 43, 49, 1);
  --collapse-list-item-bg: rgba(239, 243, 249, 1);
}

:root[data-theme='dark'] {
  --collapse-text: var(--white-color);
  --collapse-arrow: var(--white-color);
  --collapse-item-title: var(--white-color);
  --collapse-list-item-bg: var(--primary-1);
}

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  max-height: calc(100% - 80px);

  --go-collapse-body-padding-top: 0;
  --go-collapse-body-padding-right: 0;
  --go-collapse-body-padding-left: 0;
  --go-collapse-body-padding-bottom: 0;
}

.list {
  max-height: 100%;
  align-items: center;
  margin: 0;

  .listItem {
    border-bottom: 0;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--collapse-list-item-bg);
    border-radius: 4px;
    padding: 2px 8px;
    font-size: 13px;
    line-height: 16px;
    min-height: 24px;
    cursor: pointer;

    &:hover:not(.listItemActive) {
      background-color: rgba(101, 121, 151, 0.15);
    }

    &:last-child {
      margin: 0;
    }

    &Active {
      background: var(--comparing-background);
    }
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--collapse-item-title);
}

.buttons {
  display: flex;
  column-gap: 8px;
}

.panelHeader {
  display: flex;
  column-gap: 8px;
  padding: 2px 0;
  cursor: pointer;

  &Arrow {
    font-size: 16px;
    width: 16px;
    height: 16px;
    transition: var(--transition);
    color: var(--collapse-arrow);

    &Down {
      transform: rotate(180deg);
    }
  }

  &Text {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    font-family: 'Inter';
    color: var(--collapse-text);
  }
}
