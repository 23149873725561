@use 'src/packages/assets/styles/typography';

.go-theme_light {
  --context-menu-icon: rgba(120, 140, 185, 1);
  --context-menu-hover-item-bg: #3954d00d;
}

.go-theme_dark {
  --context-menu-icon: var(--white-color);
  --context-menu-hover-item-bg: #65799726;
}

:global {
  :local(.collapseButton) {
    .go-button__inner {
      margin-left: 0;

      &::after {
        display: none;
      }
    }
  }
}

.popover {
  min-width: 170px;
}

.iconButton {
  padding: 0;
  background: transparent;
  height: 16px;
  width: 16px;
  color: var(--context-menu-icon);

  .icon {
    opacity: 0.5;

    &__active {
      opacity: 1;
    }

    &.icon__active,
    &:not(.icon__active) {
      font-size: 24px;
    }
  }
}

.collapseWrapper {
  position: relative;
  background: var(--primary-2);
}

.arrowIcon {
  position: absolute;
  top: 50%;
  right: 0;
  width: 16px;
  height: 16px;
  transform: translateY(-50%) rotate(-180deg);
  transition: var(--transition);

  &Up {
    transform: translateY(-50%) rotate(0);
  }
}

.button {
  @extend %button-m;

  width: 100%;
  padding: 0 8px;
  padding-bottom: 8px;
  padding-top: 8px;
  display: block;
  background: none;
  border: none;
  text-align: left;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: var(--contrast);
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: var(--context-menu-hover-item-bg);
  }
}

.collapseButton {
  position: relative;
  padding-right: 24px;

  &:hover {
    text-decoration: none;
  }
}

.collapseContent {
  background: var(--primary-2);
  border-radius: 4px;

  &Button {
    padding: 8px;
    border-radius: 4px;
  }
}
