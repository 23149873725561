@use 'src/packages/assets/styles/typography';

:root[data-theme='light'] {
  --qwer: var(--brand-1);
}

:root[data-theme='dark'] {
  --qwer: var(--contrast);
}

.wrapper {
  padding: 16px;
  width: 440px;
  padding-bottom: 24px;
}

.header {
  color: var(--text-1);
  padding: 0;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.inputsWrapper {
  color: var(--text-1);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inputsText {
  @extend .caption;
}

.inputWrapper {
  width: 100%;
  max-width: 100%;
}

.nameInput,
.descriptionInput {
  width: 100%;
  max-width: 100%;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: var(--primary-2);
}

.inputError {
  border: 1px solid var(--red-color);
}

.descriptionInput {
  resize: none;
}

.buttonsWrapper {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.button {
  width: 100%;
}
