@use '/src/packages/assets/styles/typography';

:root[data-theme='light'] {
  --widget-template-details-input-bg: rgba(80, 101, 155, 0.1);
}

:root[data-theme='dark'] {
  --widget-template-details-input-bg: var(--primary-3);
}

.wrapper {
  @extend .fontBase;
  height: 100%;
}

.header {
  @extend .body-m-bold;

  display: flex;
  justify-content: space-between;
  column-gap: 12px;
  align-items: center;
  padding: 8px 16px 7px;
  border-bottom: 1px solid var(--settings-grid-border);
  color: var(--contrast);
}

.contentWrapper {
  display: flex;
  min-height: calc(100% - 32px);
  column-gap: 8px;
  padding: 0 20px 16px 8px;
}

.grid {
  display: flex;
  width: 100%;
  column-gap: 16px;
}

.mainColumn {
  width: 100%;
  max-width: 254px;
  margin: 0;

  &Icon {
    width: 24px;
    height: 24px;
    color: var(--white-color);
  }

  &Item {
    list-style: none;
  }

  &Button {
    @extend .fontBase;

    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding: 16px 8px;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    border-radius: 8px;
    border: none;
    background: none;
    cursor: pointer;

    &__active {
      background: var(--brand-3);
    }
  }
}

.secondLevelColumn {
  max-width: 270px;
  width: 100%;
  background-color: var(--settings-grid-column-bg);
  border-radius: 8px;
  @extend .fontBase;

  &Item {
    padding: 16px;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;

    &__active {
      background: var(--brand-3);
    }
  }
}

.column {
  @extend .secondLevelColumn;

  max-width: 100%;
}

.importButton {
  height: 24px;
  width: 24px;
  padding: 0;
  background: none;
}

.header .iconButton .icon {
  color: var(--settings-grid-icons);
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.iconButton {
  background: none;
  width: 24px;
  height: 24px;
  padding: 0;
}

.headerButton {
  display: flex;
  column-gap: 12px;
}

.content {
  padding: 16px;
}

.templateDetails {
  margin-bottom: 26px;

  .field {
    border: none;

    &Name {
      box-shadow: none;
      margin-bottom: 8px;
    }

    &Desc {
      @extend .body-s;
      resize: none;
      overflow-y: hidden;
    }

    &Input {
      @extend .body-s;
    }
  }
}

.textAreaWrapper {
  border: none;
  box-shadow: none;
  width: 100%;
  max-width: 100%;
}

.openByDefaultCheckbox {
  margin-top: 18px;
  margin-bottom: 18px;
  color: var(--brand-4);

  &Label {
    margin-left: 8px;
  }
}

.duplicateButton {
  color: var(--brand-4);
  padding: 0;
  margin-left: 4px;
}

.params {
  &Header {
    @extend .caption-fs13;
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--settings-grid-border);
    color: var(--text-1);
  }

  &Items {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    list-style: none;
    font-weight: 400;
    font-size: 13px;
    margin: 0;
    line-height: 16px;
  }

  &Name {
    @extend .caption-fs13;
    color: var(--text-1);
    margin-bottom: 8px;
  }

  &Item {
    @extend .body-s;
    color: var(--text-2);
  }
}
