.popover {
  padding: 0;
}

.menuButton {
  display: grid;
  place-items: center;
  background: none;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  margin-right: 4px;
  cursor: pointer;
  border-radius: 2px;
  color: var(--toolbar-icons);

  &__active {
    color: var(--white-color);
    background: var(--brand-1);
  }

  .icon {
    width: 18px;
    font-size: 16px;
    height: 18px;
  }
}

.wrapperScroll {
  position: relative !important;

  :global(.ScrollbarsCustom-Scroller) {
    position: relative !important;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  max-height: 323px;
  min-width: 170px;
  white-space: nowrap;
}
